import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GapDetailsData: [],
};

export const gapSlice = createSlice({
  name: "Gap",
  initialState,
  reducers: {
    setGapDetailsData: (state, action) => {
      console.log("action.payload", state, action.payload);
      state.GapDetailsData = action.payload;
    },
  },
});

export const { setGapDetailsData } = gapSlice.actions;

export default gapSlice.reducer;
