import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeMenuData: {},
};

export const activeMenuSlice = createSlice({
  name: "activeMenu",
  initialState,
  reducers: {
    setActiveMenuData: (state, action) => {
      console.log("action.payload", action.payload);
      state.activeMenuData = action.payload;
      //   console.log(state.activeMenuData);
    },
  },
});

export const { setActiveMenuData } = activeMenuSlice.actions;

export default activeMenuSlice.reducer;
