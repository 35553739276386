import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
  message: "",
  description: "",
};
export const messageBoxSlice = createSlice({
  name: "messageBox",
  initialState,
  reducers: {
    setMessageBox: (state, action) => {
      const { type = "", message = "", description = "" } = action.payload;
      state.type = type;
      state.message = message;
      state.description = description;
    },
  },
});

export const { setMessageBox } = messageBoxSlice.actions;

export default messageBoxSlice.reducer;
