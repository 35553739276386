import React, { Fragment } from "react";
import { Dropdown, Form, Input, Menu, Select } from "antd";
import {
  DownOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "../../Styles/UtilityComponents/Input/style.css";
const InputBox = (props) => {
  let {
    value,
    onChange,
    type,
    name,
    label,
    validationOn,
    required,
    disabled,
    autoComplete,
    isTextArea,
    className,
    maxLength,
    minLength,
    form,
    removeRequiredLabel,
    placeholder,
    size,
    borderLess,
    bCalledByGrid,
    formStyle,
    sToolTipText,
    addonAfter,
    addonBefore,
    addOn,
    optionDT,
    optionVal,
    optionText,
    isAddOnDropdown,
    propDefaultValue,
    onChangeDropDown,
    formClsName,
    validateRegex,
  } = props;
  value = value ? value : "";
  type = type ? type : "";
  name = name ? name : "";
  label = label ? label : "";
  size = size ? size : "middle";
  autoComplete = autoComplete ? autoComplete : "";
  required = required ? required : false;
  disabled = disabled ? disabled : false;
  isTextArea = isTextArea ? isTextArea : false;
  className = className ? className : "";
  validationOn = validationOn ? validationOn : "";
  placeholder = placeholder ? placeholder : "";
  sToolTipText = sToolTipText ? sToolTipText : "";
  isAddOnDropdown = isAddOnDropdown ? isAddOnDropdown : false;
  let defOptionDT = optionDT === undefined || optionDT === null ? [] : optionDT;

  const { Option } = Select;

  const handleSelectChange = (ValueType, OptionType) => {
    try {
      if (onChangeDropDown) {
        onChangeDropDown(ValueType, OptionType);
      }
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const selectAfter = addOn ? null : (
    <div id="addOnAfterValue">
      <Form.Item
        id="infomateInput"
        className={"m-0 p-0"}
        style={{ minHeight: "0px" }}
        required={required}
        initialValue={propDefaultValue}
        name={optionVal}
      >
        <Select
          id={"infomatinput"}
          defaultValue={propDefaultValue}
          onChange={(ValueType, OptionType) =>
            handleSelectChange(ValueType, OptionType)
          }
          style={{ width: "auto", textOverflow: "ellipsis" }}
          disabled={disabled}
          placeholder={"Select Details"}
        >
          {defOptionDT.map((itm) => {
            return (
              <Option value={itm[optionVal]} datavalue={itm}>
                {itm[optionText]}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </div>
  );

  const menu = () => (
    <Menu selectable={true}>
      {defOptionDT &&
        Array.isArray(defOptionDT) &&
        defOptionDT.length > 0 &&
        defOptionDT.map((itm, ind) => {
          return (
            <Menu.Item
              key={ind}
              id={"txt" + ind}
              // name=
              onClick={(ValueType) => {
                handleSelectChange(itm, ValueType);
              }}
            >
              {itm[optionText]}
            </Menu.Item>
          );
        })}
    </Menu>
  );

  const addOnDropDown = (
    <div id="addOnAfterValue">
      <Form.Item
        className={"m-0 p-0"}
        required={required}
        initialValue={propDefaultValue}
        name={optionVal}
        Focus={true}
      >
        <Dropdown overlay={menu} trigger={"click"}>
          <div className="d-flex flex-row">
            <SearchOutlined className="d-flex align-items-center" />{" "}
            <DownOutlined
              style={{ color: "#4096ff" }}
              className={"Quick_dropdown"}
              tabIndex={3}
            />
          </div>
        </Dropdown>
      </Form.Item>
    </div>
  );

  const handleInputChange = (value) => {
    try {
      if (onChange) {
        onChange(value);
      }
    } catch (ex) {
      console.log("ex", ex);
    }
  };
  function panValidator(panNumber) {
    console.log(" in  panNumber", panNumber);
    if (
      panNumber.length !== 10 ||
      !panNumber.match(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/)
    ) {
      return false;
    }
    const validCharacters = /^[A-Z0-9]+$/;
    if (!panNumber.split("").every((char) => validCharacters.test(char))) {
      return false;
    }
    const checkDigit =
      ((panNumber[0].charCodeAt() - 65) * 5 +
        (panNumber[1].charCodeAt() - 65) * 4 +
        (panNumber[2].charCodeAt() - 65) * 3 +
        (panNumber[3].charCodeAt() - 48) * 2 +
        (panNumber[4].charCodeAt() - 48) * 1 +
        (panNumber[5].charCodeAt() - 65) * 5 +
        (panNumber[6].charCodeAt() - 65) * 4 +
        (panNumber[7].charCodeAt() - 65) * 3 +
        (panNumber[8].charCodeAt() - 65) * 2 +
        (panNumber[9].charCodeAt() - 65)) %
      11;
    return checkDigit === panNumber[9].charCodeAt() - 65;
  }

  const rules = (isPasswordNotMatched) => {
    let rulesArray = [];
    if (required) {
      if (removeRequiredLabel) {
        rulesArray.push({
          required: required,
          message: "",
        });
      } else {
        rulesArray.push({
          required: required,
          message: validationOn ? "Enter " + validationOn : "Enter " + label,
        });
      }
    }
    if (minLength) {
      rulesArray.push({
        min: minLength,
        message:
          validationOn + " " + ` must be minimum ${minLength} characters`,
      });
    }
    if (type === "email") {
      rulesArray.push({
        type: "email",
        message: "Enter valid Email Address",
      });
    }
    if (type === "password") {
      rulesArray.push(
        ({ getFieldValue }) => ({
          validator(itm, value) {
            if (
              !value ||
              itm.field !== "confirmPassword" ||
              getFieldValue("newPassword") === value
            ) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Password do not match"));
          },
        }),
        ({ getFieldValue }) => ({
          validator(_, value) {
            let pattern =
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=!]).{8,}$/;
            if (
              !value ||
              !(getFieldValue("newPassword") === value) ||
              value.match(pattern)
            ) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error("Password must be as specified criteria")
            );
          },
        })
      );
    }
    if (validateRegex) {
      // if (validateRegex === "pancard") {
      //   rulesArray.push(({ getFieldValue }) => ({
      //     validator(_, value) {
      //       if (!value || panValidator(value)) {
      //         return Promise.resolve();
      //       }
      //       return Promise.reject(
      //         new Error(
      //           validationOn
      //             ? "Enter valid " + validationOn
      //             : "Enter valid " + label
      //         )
      //       );
      //     },
      //   }));
      // } else {
      rulesArray.push(({ getFieldValue }) => ({
        validator(_, value) {
          let pattern = validateRegex;
          if (!value || value.match(pattern)) {
            return Promise.resolve();
          }
          return Promise.reject(
            new Error(
              validationOn
                ? "Enter valid " + validationOn
                : "Enter valid " + label
            )
          );
        },
      }));
      // }
    }
    return rulesArray;
  };

  const handleNumber = (evt, itm) => {
    if (type === "number") {
      let pattern = /^[+]?[-0-9]*$/;
      if (
        (!pattern.test(evt.target.value) || !evt.key.match(pattern)) &&
        !evt.key.match("Backspace") &&
        !evt.key.match("Delete") &&
        !evt.key.match("Tab")
      ) {
        evt.preventDefault();
        return false;
      }
    }
  };

  return (
    <Fragment>
      <div id={"infomateInput"} className={formClsName}>
        <Form.Item
          id="infomateInput"
          className={formClsName}
          // className="w-100"
          name={name}
          label={label}
          form={form}
          style={formStyle}
          required={required}
          rules={rules()}
          tooltip={
            sToolTipText
              ? { title: sToolTipText, icon: <InfoCircleOutlined /> }
              : false
          }
        >
          {type !== "password" ? (
            <Input
              id={"infomateInput"}
              size={size}
              name={name}
              disabled={disabled}
              onChange={handleInputChange}
              onKeyDown={(evt) => {
                handleNumber(evt);
              }}
              maxLength={maxLength}
              placeholder={placeholder}
              label={label}
              addonAfter={
                addOn && addonAfter
                  ? addOn
                  : optionDT && addonAfter && isAddOnDropdown
                  ? addOnDropDown
                  : optionDT && addonAfter
                  ? selectAfter
                  : null
              }
              addonBefore={
                addOn && addonBefore
                  ? addOn
                  : optionDT && addonBefore && isAddOnDropdown
                  ? addOnDropDown
                  : optionDT && addonBefore
                  ? selectAfter
                  : null
              }
              // bordered={!borderLess}
              // required={required}
            />
          ) : (
            <Input.Password
              visibilityToggle
              type={type}
              id={"grid-input"}
              size={size}
              name={name}
              onChange={handleInputChange}
              onKeyDown={(evt) => {
                handleNumber(evt);
              }}
              maxLength={maxLength}
              placeholder={placeholder}
              label={label}
            />
          )}
        </Form.Item>
      </div>
    </Fragment>
  );
};

export default InputBox;
