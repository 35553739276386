import { Modal } from "antd";
import React from "react";

import "../../../../Styles/ChangePassword/stayle.css";
import ChangePasswordForm from "../../../../Pages/Login/ChangePassword/ChangePasswordForm";

const ChangePasswordModal = (props) => {
  let { isModalVisible, setIsModalVisible } = props;

  const handleOnChangePass = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOnChangePass}
      okText="Confirm"
      footer={null}
      onCancel={() => {
        setIsModalVisible(false);
      }}
    >
      <ChangePasswordForm />
    </Modal>
  );
};

export default ChangePasswordModal;
