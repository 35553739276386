import React, { Fragment } from "react";
import { Layout, Spin } from "antd";
import SideBar from "./component/SideBar";
import { Outlet } from "react-router-dom";
import HeaderComponent from "./component/Header/Header";
import {
  FileExcelOutlined,
  HomeOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
const { Content, Footer } = Layout;

const Home = () => {
  const { generalData } = useSelector((state) => state.generalDetails);
  let { isProgress } = generalData;
  const activeMenuData = useSelector((state) => state.activeMenu);
  console.log("isProgress", isProgress);
  return (
    <Fragment>
      <Layout style={{ height: "100vh" }}>
        <SideBar />
        <Layout>
          <HeaderComponent />
          <Spin size="large" spinning={isProgress ? true : false}>
            <Content
              className="site-layout"
              style={{
                margin: "50px 0px 0px 60px",
                padding: "10px 10px 10px 10px",
                overflowY: "auto",
              }}
            >
              <div style={{ height: "87vh" }}>
                {/* <Breadcrumb
                style={{
                  marginBottom: "10px",
                }}
              >
                <Breadcrumb.Item>
                  <HomeOutlined style={{ fontSize: "18px" }} />
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <div style={{ display: "flex" }}>
                    <span className="me-1">
                      {activeMenuData.activeMenuData.iconFn
                        ? activeMenuData.activeMenuData.iconFn()
                        : ""}
                    </span>

                    <span style={{ fontSize: "16px" }}>
                      {activeMenuData.activeMenuData.sMenuName}
                    </span>
                  </div>
                </Breadcrumb.Item>
              </Breadcrumb> */}

                <Outlet />
              </div>
            </Content>
          </Spin>
        </Layout>
      </Layout>
    </Fragment>
  );
};
export default Home;
