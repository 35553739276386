import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./UserStore/userSlice";
import messageBoxSlice from "./MessageBoxStore/messageBoxSlice";
import activeMenuSlice from "./MenuStore/activeMenuSlice";
import gapSlice from "./GapStore/gapSlice";
import personalDetailsSlice from "./personalDetailsStore/personalDetailsSlice";
import generalSlice from "./GeneralStore/generalSlice";
export const store = configureStore({
  reducer: {
    user: userSlice,
    messageBox: messageBoxSlice,
    activeMenu: activeMenuSlice,
    gap: gapSlice,
    personalDetails: personalDetailsSlice,
    generalDetails: generalSlice,
  },
});
