import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetailsData: {
    sFirstNam: "",
    sMiddleName: "",
    sLastName: "",
  },
};

export const personalDetailsSlice = createSlice({
  name: "Personal Details",
  initialState,
  reducers: {
    setPersonalDetailsData: (state, action) => {
      state.personalDetailsData = action.payload;
    },
  },
});

export const { setPersonalDetailsData } = personalDetailsSlice.actions;

export default personalDetailsSlice.reducer;
