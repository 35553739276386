import { Button, Form } from "antd";
import React, { Fragment, useState } from "react";
import { Col, Row } from "reactstrap";
import InputBox from "../../../UtilityComponents/Inputs/InputBox";
import { CheckOutlined, RightCircleFilled } from "@ant-design/icons";
import { KeyOutlined } from "@ant-design/icons";
import {
  changePasswordApi,
  resetPasswordApi,
} from "../../../services/APIs/Login";
import { useDispatch } from "react-redux";
import { setMessageBox } from "../../../../Store/MessageBoxStore/messageBoxSlice";

const ChangePasswordForm = (props) => {
  let { sCallBy, setPasswordChanged } = props;
  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [Loading, setLoading] = useState(false);

  const sToolTipText = () => {
    return (
      <div className="d-flex" style={{ flexDirection: "column" }}>
        <span>Password Must Contains:</span>
        <span className="d-flex-align">
          <CheckOutlined className="password-criteria-icon" />
          Atleast 1 uppercase letter (A-Z)
        </span>
        <span className="d-flex-align">
          <CheckOutlined className="password-criteria-icon" />
          Atleast 1 lower letter (a-z)
        </span>
        <span className="d-flex-align">
          <CheckOutlined className="password-criteria-icon" />
          Atleast 1 number (0-9)
        </span>
        <span className="d-flex-align">
          <CheckOutlined className="password-criteria-icon" />
          Atleast 1 special characters
        </span>
        <span className="d-flex-align">
          <CheckOutlined className="password-criteria-icon" />
          Atleast 8 characters
        </span>
      </div>
    );
  };
  const handleOnSuccess = () => {
    setLoading(false);
    if (sCallBy === "forgotPassword") {
      setPasswordChanged(true);
    }
  };
  const handleOnError = (ex) => {
    setLoading(false);
    if (ex.response) {
      console.log("ex.response", ex.response);
      let { data } = ex.response;
      let { message } = data;
      dispatch(
        setMessageBox({
          type: "error",
          message: "Error",
          description: message,
        })
      );
    } else {
      dispatch(
        setMessageBox({
          type: "error",
          message: "Something went wrong !",
        })
      );
    }
    // if (sCallBy === "forgotPassword") {
    //   setPasswordChanged(true);
    // }
  };
  const handleOnConfirm = async () => {
    try {
      let { currentPassword, newPassword } = form.getFieldValue();
      await form.validateFields();
      setLoading(true);
      if (sCallBy === "forgotPassword") {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const token = urlParams.get("token");
        console.log("token", token);
        if (newPassword) {
          let data = {
            password: newPassword,
            token: token,
          };
          changePasswordApi(data, handleOnSuccess, dispatch, handleOnError);
        }
      } else {
        let data = {
          data: {
            newPassword: newPassword,
            oldPassword: currentPassword,
          },
        };
        resetPasswordApi(data, handleOnSuccess, dispatch, handleOnError);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Fragment>
      <div className="change-password-form-box">
        <KeyOutlined
          height={100}
          width={100}
          style={{ color: "#4096ff", fontSize: "50px" }}
        />

        <span style={{ fontSize: "18px", fontWeight: 700, padding: "10px" }}>
          Change Your Password
        </span>
        <span>Enter new password below to change your password.</span>
      </div>
      <Form
        {...formItemLayout}
        layout={"vertical"}
        form={form}
        initialValues={{
          layout: "vertical",
        }}
        // onSubmit={handleOnSubmit}
      >
        {sCallBy !== "forgotPassword" ? (
          <Row>
            <Col md={12}>
              <InputBox
                type="password"
                form={form}
                size={"default"}
                label="Current Password"
                placeholder="Current Password"
                validationOn="Current Password"
                required={true}
                name={"currentPassword"}
                id={"currentPassword"}
              />
            </Col>
          </Row>
        ) : null}

        <Row>
          <Col md={12}>
            <InputBox
              type="password"
              form={form}
              size={"default"}
              label="New Password"
              placeholder="New Password"
              validationOn="New Password"
              required={true}
              name={"newPassword"}
              id={"newPassword"}
              sToolTipText={sToolTipText}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <InputBox
              type="password"
              form={form}
              size={"default"}
              label="Confirm Password"
              placeholder="Confirm Password"
              validationOn="Confirm Password"
              required={true}
              name={"confirmPassword"}
              id={"confirmPassword"}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Button
              type="primary"
              htmlType="submit"
              htmlFor="submit"
              block
              onClick={handleOnConfirm}
              loading={Loading ? true : false}
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ChangePasswordForm;
