import { BASE_URL } from "../../../contexts/constants";
import { ApiCalling } from "../ApiCalling";

export const LoginApi = async (payLoad, onSuccess, dispatch) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/login`,
    payLoad,
    onSuccess,
    dispatch,
    "",
    "Invalid Credentials"
  );
};
export const logoutApi = async (payLoad, onSuccess, dispatch) => {
  return await ApiCalling(
    "get",
    `${BASE_URL}auth/logout`,
    payLoad,
    onSuccess,
    dispatch,
    "",
    "Invalid Credentials"
  );
};

export const googleLoginApi = async (payLoad, onSuccess, dispatch) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/google-login`,
    payLoad,
    onSuccess,
    dispatch,
    "",
    "Invalid Credentials"
  );
};

export const forgotPasswordApi = async (
  payLoad,
  onSuccess,
  dispatch,
  handleOnError
) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/forgot-password`,
    payLoad,
    onSuccess,
    dispatch,
    handleOnError
  );
};
export const changePasswordApi = async (
  payLoad,
  onSuccess,
  dispatch,
  handleOnError
) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/forgot-password-update`,
    payLoad,
    onSuccess,
    dispatch,
    handleOnError
  );
};
export const resetPasswordApi = async (
  payLoad,
  onSuccess,
  dispatch,
  handleOnError
) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/reset-password`,
    payLoad,
    onSuccess,
    dispatch,
    handleOnError
  );
};

export const RegisterApi = async (
  payLoad,
  onSuccess,
  dispatch,
  handleOnError
) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/register`,
    payLoad,
    onSuccess,
    dispatch,
    handleOnError
  );
};

export const getApplicantTokenApi = async (payLoad, onSuccess, dispatch) => {
  return await ApiCalling(
    "post",
    `${BASE_URL}auth/applicant-token`,
    payLoad,
    onSuccess,
    dispatch,
    "",
    "Invalid Credentials",
    "",
    "",
    true
  );
};
