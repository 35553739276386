import React, { Fragment, useEffect, useState } from "react";
import {
  BarChartOutlined,
  UserAddOutlined,
  FileSyncOutlined,
  UserSwitchOutlined,
  SolutionOutlined,
  AppstoreOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { getCookies } from "../../../cookieHelpers/Cookie";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveMenuData } from "../../../Store/MenuStore/activeMenuSlice";
const { Sider } = Layout;

const menuDataArray = [
  // {
  //   iMenuId: 1,
  //   sMenuName: "Client Page",
  //   iParentMenuId: 0,
  //   sPath: "/clientPage/caseStatus",
  //   sIcon: "HomeOutlined",
  //   iMenuType: 2,
  // },
  {
    iMenuId: 1,
    sMenuName: "Dashboard",
    iParentMenuId: 1,
    sPath: "/Dashboard",
    iconFn: () => {
      return <BarChartOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: true,
  },
  {
    iMenuId: 2,
    sMenuName: "Case Status",
    iParentMenuId: 1,
    sPath: "/clientPage/caseStatus",
    iconFn: () => {
      return <FileSyncOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
  {
    iMenuId: 3,
    sMenuName: "Initiate Case",
    iParentMenuId: 1,
    sPath: "/clientPage/initiateCase",
    iconFn: () => {
      return <SolutionOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
  {
    iMenuId: 4,
    sMenuName: "Employee Management",
    iParentMenuId: 1,
    sPath: "/admin/employeeManagement",
    iconFn: () => {
      return <UserSwitchOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
  {
    iMenuId: 5,
    sMenuName: "Package Management",
    iParentMenuId: 1,
    sPath: "/admin/packageManagement",
    iconFn: () => {
      return <AppstoreOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
  {
    iMenuId: 6,
    sMenuName: "Client Management",
    iParentMenuId: 1,
    sPath: "/admin/clientManagement",
    iconFn: () => {
      return <TeamOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
  {
    iMenuId: 7,
    sMenuName: "User Management",
    iParentMenuId: 1,
    sPath: "/admin/userManagement",
    iconFn: () => {
      return <UserAddOutlined style={{ fontSize: "16px" }} />;
    },
    iMenuType: 3,
    defaultSelected: false,
  },
];

const userPermittedMenu = {
  0: [1, 2, 3, 4, 5, 6, 7],
  1: [1, 2, 3, 4, 5, 6, 7],
  2: [1, 2, 3, 5],
  3: [2, 3],
  4: [],
};
const SideBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const userRole = getCookies("Role");
    console.log("userRole", userRole);
    let menus = [];
    setCollapsed(true);
    menuDataArray.map((itm, ind) => {
      console.log("userPermittedMenu", userPermittedMenu[userRole]);
      if (
        userPermittedMenu[userRole] &&
        Array.isArray(userPermittedMenu[userRole]) &&
        userPermittedMenu[userRole].length &&
        userPermittedMenu[userRole].includes(itm.iMenuId)
      ) {
        menus.push(itm);
        if (itm.defaultSelected) {
          dispatch(setActiveMenuData({ ...itm, icon: "" }));
        }
      }
    });
    setMenuData(menus);
  }, []);
  const handleMenuClick = (itm) => {
    // console.log("itm", itm);
    dispatch(setActiveMenuData({ ...itm, icon: "" }));
    navigate(itm.sPath);
  };
  const handleOnCollapse = (value, type) => {
    if (type !== "responsive") {
      setCollapsed(!collapsed);
    }
  };
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={handleOnCollapse}
      breakpoint="lg"
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      collapsedWidth={60}
      style={{
        position: "fixed",
        zIndex: 999,
        height: "100vh",
      }}
    >
      {!collapsed ? (
        <div
          className="logo"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <img
            width={150}
            height={60}
            src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
          ></img>
        </div>
      ) : (
        ""
      )}
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        {menuData.map((itm, ind) => (
          <Menu.Item key={itm.iMenuId} onClick={() => handleMenuClick(itm)}>
            <div className="d-flex align-items-center">
              {itm.iconFn()}
              {/* <HomeOutlined style={{ fontSize: "16px" }} /> */}
              <span>{itm.sMenuName}</span>
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
