import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userEmail: "",
  userRole: 0,
  userCompany: "People Prime Worldwide Private Limited",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.userEmail = action.payload;
      console.log(state.userEmail);
    },
    setRole: (state, action) => {
      console.log(action.payload);
      const [role] = action.payload;
      state.userRole = role;
    },
    setCompany: (state, action) => {
      console.log(action.payload);
      const [company] = action.payload;
      state.userCompany = company;
    },
  },
});

export const { setEmail, setRole, setCompany } = userSlice.actions;

export default userSlice.reducer;
