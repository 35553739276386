import axios from "axios";
import { setMessageBox } from "../../Store/MessageBoxStore/messageBoxSlice";
import { getCookies } from "../../cookieHelpers/Cookie";
// axios.defaults.withCredentials = true;
export const ApiCalling = async (
  method,
  URL,
  data,
  handleOnSuccess,
  dispatch,
  handleOnError,
  customMsg,
  isFile,
  fromFileDownload,
  isNoMsg
) => {
  try {
    const token = getCookies("token");
    const applicantToken = getCookies("applicantToken");
    console.log("applicantToken ye wala ", applicantToken);
    let axiosParams1;
    let axiosParams2;
    let config = {
      headers: {
        token: token,
        applicantToken: applicantToken,
        "Content-Type": isFile ? "multipart/form-data" : "application/json",
        // "Content-Type": "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        Cookie: `token=${token}`,
      },
    };

    console.log("token: ", token);
    if (fromFileDownload) {
      config = {
        ...config,
        responseType: "blob",
      };
    }
    if (method === "delete") {
      // data["data"]["token"] = token;
      // axiosParams1 = data;
      axiosParams1 = { ...config, data };
    } else if (method === "get") {
      axiosParams1 = { ...config, ...data };
    } else if (method === "put" || method === "post") {
      axiosParams2 = config;
      axiosParams1 = data;
    }
    console.log("params: ", axiosParams1);
    console.log("data", data);
    console.log("headerData", config);

    await axios[method](URL, axiosParams1, axiosParams2)
      .then((response) => {
        console.log("response", response);
        if (response) {
          let { data: ResData, status } = response;
          console.log("data nnnn", ResData);
          console.log("msg prop", ResData.message);
          let { message = "" } = ResData;
          console.log("message inner", message);
          if (!isNoMsg) {
            dispatch(
              setMessageBox({
                type:
                  status === 200 ||
                  status === 201 ||
                  status === 202 ||
                  status === 204
                    ? "success"
                    : "error",
                message: message,
                // description: message,
              })
            );
          }
        }
        if (handleOnSuccess) {
          handleOnSuccess(response);
        }
      })
      .catch((ex) => {
        console.log("ex", ex);
        if (!handleOnError) {
          if (ex.response) {
            console.log("ex.response", ex.response);
            let { data } = ex.response;
            let { message } = data;
            dispatch(
              setMessageBox({
                type: "error",
                message: customMsg ? customMsg : "Error",
                description: message,
              })
            );
          } else {
            dispatch(
              setMessageBox({
                type: "error",
                message: "Something went wrong !",
              })
            );
          }
        } else {
          handleOnError(ex);
        }
      });
  } catch (error) {
    console.log("error", error);
  }
};
