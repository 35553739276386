import { Layout } from "antd";
import React, { Fragment } from "react";
import ProfileTab from "./component/ProfileTab";
const { Header } = Layout;

const HeaderComponent = () => {
  return (
    <Fragment>
      <Header
        style={{
          padding: "0px",
          background: "#fff",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          width: "100%",
          position: "fixed",
          zIndex: 10,
        }}
      >
        <ProfileTab />
      </Header>
    </Fragment>
  );
};

export default HeaderComponent;
