import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import Home from "../Layout/Home";
import MissingRoute from "../Routes/MissingRoutes";
import React, { lazy, useContext } from "react";
import Page404 from "../Pages/ErrorPages/Page404";
import AuthContext from "../../contexts/AuthProvider";
const LOGIN = lazy(() => import("../Pages/Login/Login"));
const CaseStatus = lazy(() => import("../Pages/Client/CaseStatus"));
const Dashboard = lazy(() => import("../Pages/Dashboard/Dashboard"));
const InitiateCase = lazy(() => import("../Pages/Client/InitiateCase"));
const Applicant = lazy(() => import("../Pages/Applicant/ApplicantController"));
const EmployeeManagement = lazy(() =>
  import("../Pages/Admin/EmployeeManagement")
);
const PackageManagement = lazy(() =>
  import("../Pages/Admin/PackageManagement")
);
const ChangePassword = lazy(() =>
  import("../Pages/Login/ChangePassword/ChangePassword")
);
const UserManagement = lazy(() => import("../Pages/Admin/UserManagement"));
const ClientManagement = lazy(() => import("../Pages/Admin/ClientManagement"));

const RoutesComponent = () => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);

  if (location && location.pathname === "/") {
    return <Navigate to="/login" />;
  }
  const authenticateToken = (page) => {
    if (auth) {
      return page;
    } else return <Navigate to="/login" />;
  };
  return (
    <React.Suspense fallback={<div></div>}>
      <Routes>
        <Route path="/login" element={<LOGIN />}></Route>
        <Route path="/changePassword" element={<ChangePassword />}></Route>
        <Route path="*" element={<Page404 />} />
        <Route path="/" element={authenticateToken(<Home />)}>
          <Route
            path="/dashboard"
            element={authenticateToken(<Dashboard />)}
          ></Route>
          <Route
            path="/clientPage/caseStatus"
            element={authenticateToken(<CaseStatus />)}
          />
          <Route
            path="/clientPage/initiateCase"
            element={authenticateToken(<InitiateCase />)}
          />
          <Route
            path="/applicantPage"
            element={authenticateToken(<Applicant />)}
          />
          <Route
            path="/admin/employeeManagement"
            element={<EmployeeManagement />}
          />
          <Route
            path="/admin/packageManagement"
            element={<PackageManagement />}
          />
          <Route
            path="/admin/clientManagement"
            element={<ClientManagement />}
          />
          <Route path="/admin/userManagement" element={<UserManagement />} />
          {/* </Route> */}
        </Route>
      </Routes>
    </React.Suspense>
  );
};

export default RoutesComponent;
