import React, { useEffect, useState } from "react";
import { notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setMessageBox } from "../../../Store/MessageBoxStore/messageBoxSlice";

const MessageBox = () => {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const { type, message, description } = useSelector(
    (state) => state.messageBox
  );
  const openNotificationWithIcon = (type, message, description) => {
    api[type]({
      message: message,
      description: description,
    });
  };

  useEffect(() => {
    if (type && message) {
      openNotificationWithIcon(type, message, description);
      dispatch(
        setMessageBox({
          type: "",
          message: "",
          description: "",
        })
      );
    }
  }, [message]);

  return <div>{contextHolder}</div>;
};

export default MessageBox;
