import React, { Fragment } from "react";
import RoutesComponent from "./Routes";
import "../Styles/global/style.css";
import MessageBox from "../UtilityComponents/MessageBox/MessageBox";
import { Spin } from "antd";
const App = () => {
  return (
    <Fragment>
      <div>
        <RoutesComponent />
        <MessageBox />
      </div>
    </Fragment>
  );
};

export default App;
