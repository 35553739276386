import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  generalData: {},
};

export const generalSlice = createSlice({
  name: "General",
  initialState,
  reducers: {
    setGeneralData: (state, action) => {
      console.log("general action.payload", action.payload);
      state.generalData = { ...state.generalData, ...action.payload };
      //   console.log(state.activeMenuData);
    },
  },
});

export const { setGeneralData } = generalSlice.actions;

export default generalSlice.reducer;
