import React, { Fragment, useContext, useState } from "react";
import { Avatar, Button, Dropdown, Menu } from "antd";
import { LogoutOutlined, KeyOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../../contexts/constants";
import AuthContext from "../../../../../contexts/AuthProvider";
import { useDispatch } from "react-redux";
import { deleteCookies, getCookies } from "../../../../../cookieHelpers/Cookie";
import "../../../../Styles/ProfileTab/style.css";
import ChangePasswordModal from "./ChangePasswordModal";
import { logoutApi } from "../../../../services/APIs/Login";
const ProfileTab = () => {
  const { setAuth, setLoggedInUserEmail } = useContext(AuthContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    logoutApi({}, handleOnSuccess, dispatch);
    // axios
    //   .get(`${BASE_URL}auth/logout`, {
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     // withCredentials: true,
    //   })
    //   .then((response) => {})
    //   .catch((e) => {
    //     // dispatch(setError(["Error in logging Out!", e, false]));
    //   });
  };
  const handleOpenChangePassModal = () => {
    setIsModalVisible(true);
  };
  const handleOnSuccess = (response) => {
    navigate("/");
    //setAuth(false);
    setLoggedInUserEmail("");
    deleteCookies("Role");
    deleteCookies("token");
    deleteCookies("clientCode");
    deleteCookies("sClientCode");
    deleteCookies("applicantToken");
    deleteCookies("sApplicantEmail");

    return response.status;
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        className="menu-item"
        onClick={handleOpenChangePassModal}
      >
        <div>
          <KeyOutlined style={{ marginRight: "5px" }} />
          Change Password
        </div>
      </Menu.Item>
      <Menu.Item
        key="2"
        className="menu-item"
        danger={true}
        onClick={handleLogout}
      >
        <div className="profile-menu">
          <LogoutOutlined style={{ marginRight: "5px" }} />
          LOGOUT
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <div className="d-flex  align-items-center" style={{ width: "100%" }}>
        <Dropdown
          overlay={menu}
          trigger={"click"}
          style={{ left: "10px" }}
          placement="bottomRight"
        >
          <Button id={"profile-card"} className="profile-card-cls">
            <div>
              <Avatar
                size={"default"}
                style={{
                  marginRight: "5px",
                  backgroundColor: "rgb(22 119 255 / 60%)",
                }}
                icon={<UserOutlined />}
              />
              <span style={{ fontSize: "14px", fontWeight: 600 }}>
                {getCookies("Name") ?? "Demo User"}
              </span>
            </div>
          </Button>
        </Dropdown>
      </div>
      {isModalVisible && (
        <ChangePasswordModal
          setIsModalVisible={setIsModalVisible}
          isModalVisible={isModalVisible}
        />
      )}
    </Fragment>
  );
};

export default ProfileTab;
