import { createContext, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(true);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState("");
  const [loggedInUserRole, setLoggedInUserRole] = useState(0);
  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loggedInUserEmail,
        setLoggedInUserEmail,
        loggedInUserRole,
        setLoggedInUserRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
